import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Link from 'gatsby-link';
import styled from 'styled-components';

import ClothAlterations from './ClothAlterations';
import Alterations from './Alterations';
import Popup from './Popup';
import routes from '../Routes';

import { fontSizes, colors, margins, Wrapper, Block, Header2, Title2, Logo, ClothesList,
  ErrorText, Cloth, Add, ClothText, Title3, Highlight, TextLink, TextLinkSeparator,
  mobileThresholdPixels, Slot as ClothType } from './styledComponents';
import ProgressBar from './order/ProgressBar';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import { toTwoDigits, toTwoDigitsOrMore } from '../services/formatting';
import featureSwitch from '../services/featureSwitch';
import './popup.css';

const AddLining = styled.span`
  cursor: pointer;
  padding: 20px 0px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${props => props.helpLink ? 'padding-bottom: 20px; margin-top: -15px;' : ''}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

const ClothTypes = styled.div`
  display: flex;
  width: calc(100% + 20px);
  margin: 10px -10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
`;

const Total = styled.span`
  text-align: center;
  font-size: ${fontSizes.m};
  padding: ${margins.m};
  transition: all .1s cubic-bezier(.7,0,.3,1);
  color: ${props => props.white ? colors.white : colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px;
    margin: 2px 0px;
    font-size: ${fontSizes.s};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  color: ${colors.navy};
  font-size: 1em;
  background: ${colors.lightGrey};
`;

function openPopup({ origin }) {
  trackEvent('click', `ouvrir-popup-aide-retouche_${origin}`);
  Popup.create({
    title: 'Aide',
    content: (<div>{`
      Cette estimation nous permet d'avoir une idée de la quantité de travail mais
      ce n'est pas grave si elle n'est pas exacte.
      En effet, votre tilliste pourra réajuster cette estimation lors de votre rdv.
      Vous pourrez par exemple ajouter d'autres vêtements à retoucher.
    `}<br /><br />{`
      Vous pouvez aussi nous poser vos questions par chat (en bas à droite de la fenêtre)
      ou par mail à contact@tilli.fr
    `}<br /><br />{`
      Vous ne savez vraiment pas quoi choisir ? Pas de problème, vous pouvez aussi passer cette étape 😉
    `}</div>),
    buttons: {
      right: [{
        text: 'Fermer',
        action() { Popup.close(); },
      }],
    },
  });
}

function renderAlterations({ showChoicesCloth, showChoicesClothRef, openCategory, openedCategory,
  showChoicesClothIndex, toggleOrSetAlteration, setClothType, submitCloth, addLining, removeLining,
  clothesNumber, submit }) {
  return (
    showChoicesCloth && showChoicesClothRef ?
      <div>
        <Title3>
          {featureSwitch('step2_showTitleWithoutCloth') ? 'je choisis ses retouches' :
            `je choisis mes retouches pour ${showChoicesCloth.name}`
          }
        </Title3>
        {showChoicesClothRef.types && (showChoicesClothRef.types.length > 0) &&
          (<ClothTypes>
            {showChoicesClothRef.types.map(type => (
              <ClothType
                key={type.slug}
                onClick={() => setClothType(showChoicesClothIndex, type.slug)}
                isSelected={showChoicesCloth.clothType === type.slug}
              >{type.name}</ClothType>
            ))}
          </ClothTypes>)
        }

        {!featureSwitch('step2_noLining') &&
          <Row>
            <span style={{ color: colors.navy }}>nombre de doublures :</span>
            <span style={{ color: colors.navy }}>
              <AddLining onClick={() => removeLining(showChoicesClothIndex)}>{'- '}</AddLining>
              <span>{toTwoDigits(showChoicesCloth.liningsNumber)}</span>
              <AddLining onClick={() => addLining(showChoicesClothIndex)}>{' +'}</AddLining>
            </span>
          </Row>
        }

        <Alterations
          clothRef={showChoicesClothRef}
          openedCategory={openedCategory}
          openCategory={openCategory}
          submitCloth={submitCloth}
          submitLast={submit}
          cloth={showChoicesCloth}
          toggleOrSetAlteration={(alterationId, quantity) =>
            toggleOrSetAlteration(showChoicesClothIndex, alterationId, quantity)}
          isLastCloth={(showChoicesClothIndex + 1) === clothesNumber}
        />
      </div> : <div />
  );
}

renderAlterations.propTypes = {
  toggleOrSetAlteration: PropTypes.func.isRequired,
  showChoicesCloth: PropTypes.shape({}),
  showChoicesClothRef: PropTypes.shape({}),
  showChoicesClothIndex: PropTypes.number,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  setClothType: PropTypes.func.isRequired,
  submitCloth: PropTypes.func.isRequired,
  addLining: PropTypes.func.isRequired,
  removeLining: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  clothesNumber: PropTypes.number,
};

renderAlterations.defaultProps = {
  showChoicesCloth: undefined,
  showChoicesClothRef: undefined,
  showChoicesClothIndex: undefined,
  openedCategory: undefined,
  clothesNumber: 0,
};

function renderClothesList(clothes, setChoicesCloth, deleteCloth, showChoicesClothIndex) {
  return (
    <ClothesList>
      {clothes.map((cloth, index) => {
        const clothNumber = clothes
          .filter((cloth2, index2) => cloth2.slug === cloth.slug && index >= index2)
          .length;
        return (<ClothAlterations
          isSelected={index === showChoicesClothIndex}
          clothNumber={clothNumber}
          cloth={cloth}
          toggleChoices={clothRef => setChoicesCloth(clothRef, cloth, index)}
          deleteCloth={() => deleteCloth(index, cloth.slug)}
          key={cloth.name}
        />);
      })}
      <Cloth onClick={() => navigate(routes.Step1.url)}>
        <ClothText>ajouter un</ClothText>
        <ClothText>vêtement</ClothText>
        <ClothText>ou déco</ClothText>
        <Add>+</Add>
      </Cloth>
    </ClothesList>
  );
}

const Step2 = ({ clothes, toggleOrSetAlteration, total, showChoicesCloth, showChoicesClothRef, openedCategory,
  openCategory, setChoicesCloth, submitCloth, showChoicesClothIndex, goNext, hasError,
  deleteCloth, setClothType, addLining, removeLining }) => (
  // eslint-disable-next-line
  <div>
    <Wrapper>
      <Block id="alterations">
        <Header2>
          <Link
            to={'/'} style={{ flex: 1, textAlign: 'center' }}
            onClick={() => trackEvent('click', 'home_step2')}
          >
            <Logo src={LogoImg} alt="Votre retoucheur à domicile" />
          </Link>
          <ProgressBar step={2} />
          <Title2>
            {featureSwitch('order_thirdPersonLabels') ?
              <span>je fais <Highlight>l‘estimation</Highlight> pour mon client</span> :
              <span>mon <Highlight>estimation</Highlight></span>
            }
          </Title2>
        </Header2>

        {featureSwitch('step2_renderClothList') &&
          renderClothesList(clothes, setChoicesCloth, deleteCloth, showChoicesClothIndex)}

        {renderAlterations({
          showChoicesCloth,
          showChoicesClothRef,
          openCategory,
          openedCategory,
          showChoicesClothIndex,
          toggleOrSetAlteration,
          submitCloth,
          setClothType,
          addLining,
          removeLining,
          clothesNumber: clothes.length,
          submit: goNext,
        })}

        {!featureSwitch('alterations_hidePrice') &&
          <Total>total : {toTwoDigitsOrMore(total)}€</Total>
        }

        {hasError &&
          <ErrorText>Merci de sélectionner des retouches pour tous les vêtements ou objets de décoration</ErrorText>}

        {!featureSwitch('alterations_hideSkipOption') &&
          <Center>
            <TextLink onClick={() => openPopup({ origin: 'page' })}>
              je ne sais pas quoi choisir</TextLink>
            <TextLinkSeparator>ou</TextLinkSeparator>
            <TextLink onClick={() => goNext({ validation: false })}>je veux passer cette étape</TextLink>
          </Center>
        }
      </Block>
    </Wrapper>
  </div>
);

Step2.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toggleOrSetAlteration: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  showChoicesCloth: PropTypes.shape({}),
  showChoicesClothRef: PropTypes.shape({}),
  showChoicesClothIndex: PropTypes.number,
  openCategory: PropTypes.func.isRequired,
  openedCategory: PropTypes.string,
  setChoicesCloth: PropTypes.func.isRequired,
  submitCloth: PropTypes.func.isRequired,
  deleteCloth: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  setClothType: PropTypes.func.isRequired,
  addLining: PropTypes.func.isRequired,
  removeLining: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  showChoicesCloth: undefined,
  showChoicesClothRef: undefined,
  showChoicesClothIndex: undefined,
  openedCategory: undefined,
  hasError: false,
};

export default Step2;
