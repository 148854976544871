import React from 'react';
import AlterationsContainer from '../containers/AlterationsContainer';
import Layout from '../../../layouts/index';
import routes from '../../../Routes';

const nextStep = routes.EstimationVariantFabrics.url;
const Step2Alterations = () => (
  <Layout slug="EstimationVariantAlterations">
    <AlterationsContainer nextStep={nextStep} />
  </Layout>
);
export default Step2Alterations;
