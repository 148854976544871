import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Quantity, ClothText, Cloth, Delete } from './styledComponents';
import pricing from '../services/pricing';
import assets from '../assets/assets';

const nameLengthTreshold = 18;

const ClothAlterations = ({ cloth, toggleChoices, deleteCloth, isSelected }) => {
  const icon = assets[cloth.slug];
  const slug = cloth.slug;
  const clothRef = pricing.find(clothIt => clothIt.slug === slug);
  const alterationsNumber = (Object.keys(cloth.alterations || {}))
    .filter(alterationId => cloth.alterations[alterationId] > 0).length;
  return (
    <div style={{ position: 'relative', opacity: isSelected ? 1 : 0.3 }}>
      <Cloth onClick={() => toggleChoices(clothRef)}>
        <Icon className={`flaticon-${icon}`} hideOnMobile={cloth.name.length > nameLengthTreshold} />
        <ClothText>
          {cloth.name.replace('/', ' / ').toLocaleLowerCase()}
        </ClothText>
        <Quantity>{alterationsNumber}</Quantity>
      </Cloth>
      {typeof deleteCloth === 'function' && <Delete onClick={deleteCloth}>-</Delete>}
    </div>
  );
};

ClothAlterations.propTypes = {
  cloth: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  toggleChoices: PropTypes.func.isRequired,
  deleteCloth: PropTypes.func,
  isSelected: PropTypes.bool,
};

ClothAlterations.defaultProps = {
  deleteCloth() {},
  isSelected: false,
};

export default ClothAlterations;
